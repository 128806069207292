import { Call, CallEnd, Mic, MicOff, PhotoCamera } from "@mui/icons-material"
import { constraintsType, memberType, userType } from "../helpers/types/types";
import { MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { PeerContext } from "../context/PeerContext";
import { DataConnection, MediaConnection } from "peerjs";
import ringingSound from '../assets/audio/makingCall.mp3';
import callingSound from '../assets/audio/phoneRingTone.mp3';
import { t } from "i18next";
import toast, { Toaster } from "react-hot-toast";
import { mockMediaStreamTrack } from "../helpers/common";

const Streaming = (props: { user: userType, member: memberType, blurTyping: Function }) => {
    const { user, member } = props;
    const peerClient = useContext(PeerContext);
    const [peerConnection, setPeerConnection] = useState<DataConnection | undefined>();
    const [localConnection, setLocalConnection] = useState<DataConnection | undefined>();
    const [dataConnection, setDataConnection] = useState<DataConnection | undefined>();
    const [mediaConnection, setMediaConnection] = useState<MediaConnection | undefined>();

    /**
     * Possible values: voice_calling voice_call_calling, in_voice_call
     */
    const [callStatus, setCallStatus] = useState('');
    // Will ring untill it reaches 0
    const RINGING_DELAY = 15;
    const [callTimer, setCallTimer] = useState(0);

    const [peerStream, setPeerStream] = useState<MediaStream | undefined>();
    const [localStream, setLocalStream] = useState<MediaStream | undefined>();


    // Video stream
    const peerVideoStreamRef = useRef<HTMLVideoElement | null>(null);
    const localVideoStreamRef = useRef<HTMLVideoElement | null>(null);

    const remotePeerAudioRef = useRef<HTMLAudioElement>(null);
    const incomingCallSoundEffectRef = useRef<HTMLAudioElement>(null);
    const outgoingCallSoundEffectRef = useRef<HTMLAudioElement>(null);
    // const VideoResolution = { width: 640, height: 480 };
    const VideoResolution = false;
    const [devicesPermissionConstraints, setDevicesPermissionConstraints] = useState<constraintsType>({ audio: true, video: VideoResolution });


    useEffect(() => {
        initPeerClient();
    }, [peerClient, callTimer, callStatus]);

    /**
     * 
     * @returns Promise.resolve(MediaStream) | promise.reject(reason)
     */
    const getMediaStream = (constraints: constraintsType | null = null): Promise<MediaStream> => {
        const ___ = constraints ? constraints : devicesPermissionConstraints;

        return navigator.mediaDevices.getUserMedia(___)
            .then(stream => {
                /**
                 * Why this? Well, This is a workarround, the concept is to always share
                 * the stream with a video track so it becomes easier later on to replace it with
                 * user camera to simplify video streaming later
                 */
                if (!___.video) {
                    const vtrack = mockMediaStreamTrack();
                    if (vtrack) stream.addTrack(vtrack);
                    if (!vtrack) console.warn('Couldn\' mock video track');
                }
                return Promise.resolve(stream);
            })
            .catch(reason => Promise.reject(reason))

        /*
            if (!navigator.mediaDevices?.enumerateDevices) {
                return Promise.reject("enumerateDevices() not supported.");
            } else {
                // List cameras and microphones.
                toast("You must allow microphone access to be able to perform calls");
                return navigator.mediaDevices
                    .enumerateDevices()
                    .then((devices) => {
                        const audioInputs = devices.find((device) => device.kind === 'audioinput');
                        if (audioInputs) {
                            return Promise.resolve(audioInputs);
                        } else {
                            return Promise.reject('No audio input was found!');
                        }
                    })
                    .catch((err) => {
                        toast("Microphone permission denied!");
                        return Promise.reject(`${err.name}: ${err.message}`);
                    });
            }
        */
    }

    const initPeerClient = () => {
        // Initiate peer connect
        const localConn = peerClient?.connect(member.id);
        localConn?.on('open', () => {
            setLocalConnection(localConn);
            setDataConnection(localConn);
            // Handle call rejection or end cancellation
            localConn.on('data', (data) => {
                if (data === 'hang_off') {
                    endVoiceCall();
                }
            })
        });

        // Accept peer connection
        peerClient?.on('connection', (peerConn) => {
            setPeerConnection(peerConn);
            setDataConnection(peerConn);
            // Handle call rejection or end cancellation
            peerConn.on('data', (data) => {
                if (data === 'hang_off') {
                    endVoiceCall();
                }
            })
        });


        peerClient?.on('call', (call) => {
            // 
            setCallStatus('voice_call_calling');
            startRingingSoundEffect(incomingCallSoundEffectRef.current);
            props.blurTyping();
            setMediaConnection(call);
        })
    }

    const launchOrAnswerVoiceCall = () => {
        if (!dataConnection) {
            return console.warn('Not connected');
        }

        if (callStatus === 'voice_call_calling') {
            return answerVoiceCall();
        }

        if (callStatus !== 'voice_calling' && !peerClient && !localConnection) {
            return console.warn('Peer client corrupt.');
        }
        // Making voice call (audio stream)
        getMediaStream()
            .then(_localStream => {
                setLocalStream(_localStream);

                startRingingSoundEffect(outgoingCallSoundEffectRef.current);

                const call = peerClient?.call(member.id, _localStream);
                setCallStatus('voice_calling');
                setMediaConnection(call);

                call?.on('close', () => {
                    endVoiceCall();
                })
                call?.on('error', () => {
                    endVoiceCall();
                })

                call?.on('stream', (_remoteStream) => {
                    setPeerStream(_remoteStream)

                    // Start call
                    callStarted();

                    stopRinging();
                    playVideoStream(_localStream, localVideoStreamRef, true);
                    playVideoStream(_remoteStream, peerVideoStreamRef);
                });
            })
            .catch(reason => {
                console.error('Cannot make call', reason)
            })
    }

    const answerVoiceCall = () => {
        if (!mediaConnection) return console.warn('No incoming call to answer!');

        stopRinging();

        // Add localStream to be shared with peer
        // Answering voice call (audio stream)
        getMediaStream()
            .then(_localStream => {
                mediaConnection.answer(_localStream);
                callStarted();

                mediaConnection.on('stream', (_remoteStream) => {
                    setLocalStream(_localStream);
                    setPeerStream(_remoteStream);
                    playVideoStream(_localStream, localVideoStreamRef, true);
                    playVideoStream(_remoteStream, peerVideoStreamRef);
                });

                mediaConnection.on('close', () => {
                    endVoiceCall();
                })
                mediaConnection.on('error', () => {
                    endVoiceCall();
                })
            })
            .catch((reason) => {
                console.warn('reason', reason)
            });
    }

    var callTimerIntervalId: null | NodeJS.Timer = null;
    const callStarted = () => {
        setCallStatus('in_voice_call');

        // Clear if any previous interval running
        if (callTimerIntervalId) clearInterval(callTimerIntervalId);
        setCallTimer(0);

        callTimerIntervalId = setInterval(() => {
            setCallTimer((prev) => prev + 1);
        }, 1000);
    }


    const endVoiceCall = () => {
        if (['voice_call_calling', 'voice_calling'].includes(callStatus)) {
            // Send rejection signal to peer
        }

        // Clear if any previous interval running
        if (callTimerIntervalId) clearInterval(callTimerIntervalId);
        setCallTimer(0);

        dataConnection?.send('hang_off');


        mediaConnection?.close();


        setLocalStream(undefined);
        setPeerStream(undefined);
        setCallStatus('');

        stopRinging();
        // Reset call settings
        setDevicesPermissionConstraints({ video: false, audio: true });

        releaseMediaDevices();
    }


    const startRingingSoundEffect = (audioElement: HTMLAudioElement | null) => {
        if (!audioElement) return;
        try {
            audioElement.setAttribute('ringTimer', RINGING_DELAY.toString());
            if (navigator.userActivation?.hasBeenActive) audioElement?.play();

            const __iid = setInterval(() => {
                const __ = audioElement.getAttribute('ringTimer');
                const ringTimer = parseInt(__ ? __ : '');
                if (ringTimer > 0) {
                    audioElement.setAttribute('ringTimer', (ringTimer - 1).toString());
                    if (navigator.userActivation?.hasBeenActive) audioElement?.play();
                } else {
                    clearInterval(__iid);

                    // Only trigger endCall() when the timer explicitly reaches 0,
                    // otherwise it may be answered which will result in NaN
                    // which will end up at the else statements and could potentially end an ongoing call!
                    if (ringTimer === 0) {
                        endVoiceCall();
                    }

                }
            }, 1000);
        } catch (error) {
            console.warn('error playing ringtone', error);
        }
    }

    const stopRinging = (incoming: boolean = true, outgoing: boolean = true) => {
        [outgoingCallSoundEffectRef.current, incomingCallSoundEffectRef.current].forEach(ringtone => {
            if (ringtone) {
                ringtone.setAttribute('ringTimer', '');
                ringtone.pause();
            }
        })
    }

    /**
     * 
     * @param mediaStream The media stream itself
     * @param videoStreamRef The videoStreamHtmlRef where to display the stream
     * @param isLocalStream If the media stream is local (mine) or remote,
     * its purpose is to disable local audio preventing user from hearing his voice
     * @returns 
     */
    const playVideoStream = (mediaStream: MediaStream, videoStreamRef: MutableRefObject<HTMLVideoElement | null>, isLocalStream = false) => {
        if (!videoStreamRef.current || !(mediaStream.getVideoTracks().length || mediaStream.getAudioTracks().length)) return;

        try {
            if (!isLocalStream && mediaStream && remotePeerAudioRef.current) {
                const peerAudioStream = mediaStream.getAudioTracks()[0];
                const ms = new MediaStream();
                ms.addTrack(peerAudioStream);
                remotePeerAudioRef.current.srcObject = ms;
                remotePeerAudioRef.current.play()
                    .then(() => { console.log('Attached and played peer\s audio'); })
                    .catch(() => { console.warn('cannot plau pee audio') })
            }

            videoStreamRef.current.srcObject = mediaStream;
            videoStreamRef.current.play()
                .then(() => { })
                .catch(() => { })
        } catch (error) {
            console.warn(error);
        }
    }

    const getCallStatus = () => {
        switch (callStatus) {
            case 'voice_calling':
                return t('calling');
            case 'voice_call_calling':
                return t('peer_calling');
            case 'in_voice_call':
                return t('in_call');
            default:
                return '';
        }
    }

    const getCallTime = (): string => {
        if (callTimer < 1) return '';

        var seconds = callTimer;


        let hours = 0;
        let mins = 0;

        if (seconds >= 3600) {
            seconds = seconds % 3600;
            hours = (callTimer - seconds) / 3600;
        }

        if (seconds >= 60) {
            mins = (seconds - (seconds % 60)) / 60;
            seconds = seconds % 60;
        }


        let result = `${hours}`;

        result += mins < 10 ? `:0${mins}` : `:${mins}`;
        result += seconds < 10 ? `:0${seconds}` : `:${seconds}`;

        return result;
    }


    const toggleCameraUsage = () => {
        if (!localStream || !mediaConnection) return;


        if (!devicesPermissionConstraints.video) {
            getMediaStream({ ...devicesPermissionConstraints, video: true })
                .then(stream => {
                    setDevicesPermissionConstraints((prev) => ({ ...prev, video: true }));
                    setLocalStream(stream);
                    playVideoStream(stream, localVideoStreamRef, true);
                    const videoTrack = stream.getVideoTracks()[0];
                    mediaConnection.peerConnection.getSenders().forEach(sender => {
                        if (sender.track?.kind === 'video') {
                            sender.replaceTrack(videoTrack);
                        }
                    })
                })
                .catch(reason => { console.warn(reason) })
        } else {
            setDevicesPermissionConstraints((prev) => ({ ...prev, video: false }));
            if (localStream) {
                localStream.getVideoTracks().forEach(vt => vt.stop());
            }
            mediaConnection.peerConnection.getSenders().forEach(sender => {
                if (sender.track?.kind === 'video') {
                    sender.track.stop();
                }
            })
        }
    }

    const toggleMic = () => {
        if (!mediaConnection) return;

        mediaConnection.peerConnection.getSenders().forEach(sender => {
            if (sender.track?.kind === 'audio') {
                const newState = !devicesPermissionConstraints.audio;
                sender.track.enabled = newState;
                setDevicesPermissionConstraints((prev) => ({ ...prev, audio: newState }));
            }
        })
    }

    const releaseMediaDevices = () => {
        // Stop senders (audio and video tracks)
        if (mediaConnection && mediaConnection.peerConnection) {
            mediaConnection.peerConnection.getSenders().forEach(sender => {
                if (sender.track) {
                    sender.track.stop();
                }
            })
        }

        localVideoStreamRef.current?.pause();
        peerVideoStreamRef.current?.pause();

        if (localStream) {
            localStream.getTracks().forEach(mst => mst.stop());
        }

        setPeerStream(undefined);
        setLocalStream(undefined);

    }

    return (
        <div className="w-full place-items-end flex mb-auto">
            <p className="w-full text-center text-base text-primary-700 dark:text-primary-100">{getCallStatus()}</p>
            <div className="flex">
                {
                    ['voice_calling', 'in_voice_call'].includes(callStatus) ? <></>
                        : <button onClick={() => launchOrAnswerVoiceCall()} >
                            <Call className={[
                                "p-0.5 rtl:rotate-180 rounded-full rtl:ml-2 ltr:mr-2 text-teal-500 dark:text-teal-300 shadow-teal-300 dark:shadow-teal-700",
                                "ltr:rotate-180 rtl:rotate-0 cursor-pointer"
                            ].join(' ')} style={{ boxShadow: `0px 0px 5px 1px var(--tw-shadow-color)` }} />
                        </button>
                }

                {
                    !callStatus ? <></>
                        : <button onClick={() => endVoiceCall()} >
                            <Call className={[
                                "p-0.5 rtl:rotate-180 rounded-full rtl:ml-2 ltr:mr-2 text-red-500 dark:text-red-300 shadow-red-300 dark:shadow-red-700",
                                "ltr:rotate-180 rtl:rotate-0 cursor-pointer"
                            ].join(' ')} style={{ boxShadow: `0px 0px 5px 1px var(--tw-shadow-color)` }} />
                        </button>
                }

            </div>


            <audio controls={false} ref={remotePeerAudioRef} autoPlay={false} muted={false} />
            <audio controls={false} ref={incomingCallSoundEffectRef} src={callingSound} autoPlay={false} />
            <audio controls={false} ref={outgoingCallSoundEffectRef} src={ringingSound} autoPlay={false} />


            {/* VideoStreamContainer */}
            {/* Render in modal */}

            <div className={[
                (callStatus) ? '' : 'hidden',
                "fixed top-0 left-0 right-0 bottom-0 backdrop-blur-sm z-40",
                "w-full md:w-2/3 h-full md:py-24 mx-auto content-center"
            ].join(' ')}>
                <div className="place-content-center relative w-full h-full mx-auto content-center py-2 md:py-1">
                    {
                        <div className="h-[90%] w-[95%] mx-auto my-auto items-center content-center bottom-[50px] bg-primary-300/20 dark:bg-primary-700/20">
                            <video controls={false}
                                className={!devicesPermissionConstraints.video ? "w-[1px] h-[1px]" : "w-auto max-w-full h-full mx-auto rounded-md shadow-lg shadow-primary-100/50 dark:shadow-primary-700/50"}
                                style={{ boxShadow: `0px 0px 5px 1px var(--tw-shadow-color)` }}
                                ref={peerVideoStreamRef}
                                muted={true}
                            />
                            <p className="text-center text-xl text-primary-700 dark:text-primary-200">{!devicesPermissionConstraints.video ? member.pseudoname : ''}</p>
                            <p className="text-center text-xl text-primary-700 dark:text-primary-200 italic">{getCallTime()}</p>
                            <p className="text-center text-xl text-primary-700 dark:text-primary-200 italic">{getCallStatus()}</p>
                        </div>
                    }

                    <video controls={false}
                        className={["absolute bottom-[50px] rtl:left-1 ltr:right-2 rounded-md",
                            devicesPermissionConstraints.video ? "h-auto w-[150px]" : "h-[1px] w-[1px]"
                        ].join(' ')}
                        ref={localVideoStreamRef}
                        muted={true}
                    />

                    <div className={[
                        "absolute w-full h-[50px] py-2 bg-primary-100 dark:bg-black/70 flex place-content-center",
                        !['in_voice_call'].includes(callStatus) ? "bottom-20" : 'bottom-2'
                    ].join(' ')}>
                        {/* On/Off Mic */}
                        {
                            !['in_voice_call'].includes(callStatus)
                                ? <></>
                                : <button onClick={toggleMic} className={[
                                    "ltr:mr-2 rtl:ml-2 w-[40px] h-[40px] bg-primary-100 dark:bg-black/80 rounded-full text-primary-500 dark:text-primary-300 shadow-primary-300 dark:shadow-primary-700",
                                ].join(' ')} style={{ boxShadow: `0px 0px 5px 1px var(--tw-shadow-color)` }}>
                                    {
                                        devicesPermissionConstraints.audio
                                            ? <Mic className="rounded-full rtl:ml-2 cursor-pointer w-10 h-10 mx-auto" />
                                            : <MicOff className="rounded-full rtl:ml-2 cursor-pointer w-10 h-10 mx-auto" />
                                    }
                                </button>
                        }

                        {/* Hang off */}
                        <button onClick={endVoiceCall} className="mx-2 w-[40px] h-[40px] bg-primary-100 dark:bg-black/80 rounded-full text-red-500 dark:text-red-300 shadow-red-300 dark:shadow-red-700" style={{ boxShadow: `0px 0px 5px 1px var(--tw-shadow-color)` }}>
                            <CallEnd className={[
                                "rtl:rotate-180 rounded-full",
                                "ltr:rotate-45 rtl:rotate-0 cursor-pointer w-10 h-10"
                            ].join(' ')} />
                        </button>

                        {/* Answer call */}
                        {
                            !['voice_call_calling'].includes(callStatus)
                                ? <></>
                                : <button onClick={answerVoiceCall} className="mx-2 w-[40px] h-[40px] bg-primary-100 dark:bg-black/80 rounded-full text-teal-500 dark:text-teal-300 shadow-teal-300 dark:shadow-teal-700" style={{ boxShadow: `0px 0px 5px 1px var(--tw-shadow-color)` }}>
                                    <Call className={[
                                        "p-0.5 rtl:rotate-180 rounded-full rtl:ml-2 ltr:mr-2 text-teal-500 dark:text-teal-300 shadow-teal-300 dark:shadow-teal-700",
                                        "ltr:rotate-180 rtl:rotate-0 cursor-pointer"
                                    ].join(' ')} style={{ boxShadow: `0px 0px 5px 1px var(--tw-shadow-color)` }} />
                                </button>

                        }

                        {/* On/Off/switch Cam */}
                        {
                            true || !['in_voice_call'].includes(callStatus)
                                ? <></>
                                : <button onClick={toggleCameraUsage} className="w-[40px] h-[40px] bg-primary-100 dark:bg-black/80 rounded-full text-primary-500 dark:text-primary-300 shadow-primary-300 dark:shadow-primary-700" style={{ boxShadow: `0px 0px 5px 1px var(--tw-shadow-color)` }}>
                                    <PhotoCamera className={[
                                        "rounded-full rtl:ml-2",
                                        "cursor-pointer w-10 h-10 mx-auto"
                                    ].join(' ')} />
                                </button>
                        }
                    </div>
                    {/* Call controls */}
                </div>

            </div>

            <Toaster />
        </div>
    )
}


export default Streaming;