import { ArrowForward } from "@mui/icons-material";
import { ChangeEvent, FormEvent, FormEventHandler, useRef, useState } from "react";
import { useTranslation } from "react-i18next";


const SignIn = (props: { setUserName: Function }) => {
    const { t } = useTranslation();

    const [pseudoname, setPseudoName] = useState('');

    const pseudonameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPseudoName(e.target.value);
    }

    const join = (el: FormEvent<HTMLFormElement>) => {
        el.preventDefault();

        if(pseudoname.length > 2) {
            props.setUserName(pseudoname);
        }
    }

    return (
        <form className=' bg-white dark:bg-primary-950/60 md:py-10 rtl:pr-2 rtl:md:px-24 md:px-24 shadow-primary-100 dark:shadow-primary-950 shadow-lg' onSubmit={(ev) => join(ev)}>
            <div className='mb-2'>
                <label className="block p-1 my-2 text-base text-primary-700 dark:text-primary-300" htmlFor='pseudoname' >{t('typePseudoName')}</label>
                <input className="block rounded-md py-3 px-1 outline-0 border-0 shadow-primary-200 dark:shadow-primary-900 shadow bg-primary-50 dark:bg-primary-950 text-primary-700 dark:text-primary-300 placeholder:text-primary-500/50" type='text' name="pseudoname" id="pseudoname" placeholder={t('pseudoname')} value={pseudoname} onChange={pseudonameChange} />
            </div>

            <div className='mt-4'>
                <button type='submit' className='bg-primary-400 text-white hover:bg-primary-400 dark:bg-primary-950 dark:text-primary-300 px-4 py-2 rounded-sm dark:hover:bg-primary-950/80 active:bg-primary-900 transition-all outline-none'>
                    <span className=''>{t('joinChat')}</span>
                    <ArrowForward className='ml-1 rtl:rotate-180 rtl:mr-2' />
                </button>
            </div>
        </form>
    );
}

export default SignIn;