import { darkColors } from '../helpers/colors';
import { v4 as uuid4 } from 'uuid';

const delimeters = ['a', 'e'];


const generateShortName = (name: String): String => {
    if (!name) return '';
    return [name[0], name[name.length - 1]].join('').toUpperCase();
}

const getBgHexaColorFromName = (name: String): String => {
    if (!name) {
        name = 'abc';
    }
    const index = name.split('').reduce((carry, val) => { return carry += val.charCodeAt(0) }, 0) % darkColors.length
    return darkColors[index];
}

const formatDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = { hour: "numeric", minute: "2-digit" };
    let formattedTime = date.toLocaleTimeString(navigator.language, options);
    return formattedTime;
}


const generateUniqueId = (): string => {
    const cts = (new Date()).getTime();
    const uuid = uuid4();
    return `${uuid}-${cts}`;
}


const HashString = (pseudoNameValue: string, shouldRandomize = false): string => {
    // Randomize the PeerID by include the current TimeStamp in the generated PeerID to ensure its uniqueness
    // This suppoze to only executes on creating new Peer instance (new Peer())
    // which will ensure the uniqueness of the PeerID
    if (shouldRandomize) {
        const currentTime = (new Date()).getTime();
        pseudoNameValue = `${pseudoNameValue}[${currentTime}]`;
    }

    const pseudoChars = pseudoNameValue.split('').map(char => `${delimeters[0]}${char.charCodeAt(0)}${delimeters[1]}`)
    return pseudoChars.join('');
}

const UnhashString = (sanitizedPseudoNameValue: string): string => {
    // const expr = /\{[0-9]+\}/g;
    const expr = `/${delimeters[0]}[0-9]+${delimeters[1]}/g`;
    const occurences = sanitizedPseudoNameValue.match(eval(expr));
    occurences?.forEach(match => {
        let code = parseInt(match.slice(delimeters[0].length, delimeters[1].length * -1));
        sanitizedPseudoNameValue = sanitizedPseudoNameValue.replace(match, String.fromCharCode(code));
    });

    // Exclude the appended TimeStamp in the generated PeerIDs for uniqueness guarantee
    const cleanPseudoName = sanitizedPseudoNameValue.replace(/\[[0-9]+\]$/, '');

    return cleanPseudoName;
}

const sort_and_join_strings = (a: string, b: string): string => {
    let res = '';


    // 

    return res;
}


const mockMediaStreamTrack = (memberName: string | null = null) => {
    // Create a canvas element
    const canvas = document.createElement('canvas');
    canvas.width = 640;  // Set the canvas resolution (e.x: 640)
    canvas.height = 480; // (e.x: 480)

    const context = canvas.getContext('2d');
    if (!context) return null;

    // Draw something on the canvas (this will appear as the video content)
    context.fillStyle = 'blue';
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = 'white';
    context.font = '30px Arial';
    context.fillText(memberName ? memberName : '', 50, 240);

    // Get the MediaStreamTrack from the canvas
    // 30 FPS
    const vtrack = canvas.captureStream(30).getVideoTracks()[0];
    vtrack.enabled = true;
    return vtrack;
}


export { generateShortName, getBgHexaColorFromName, formatDate, HashString, UnhashString, generateUniqueId, mockMediaStreamTrack };