import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { userType, memberType, messageType } from "../helpers/types/types";
import { formatDate } from "../helpers/common";
import { Adjust, ArrowBack, ArrowDownward, Call, Check, Circle, Drafts, MoreHoriz, SendOutlined } from "@mui/icons-material";
import { Grid, ThreeDots } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { v4 as uuid4 } from "uuid";
import toast, { Toaster } from 'react-hot-toast';
import toastNow from "../helpers/toast";
import incommingMessageSound from '../assets/audio/AKS3.m4a';
import outgoingMessageSound from '../assets/audio/AKS1.m4a';
import Streaming from "./Streaming";

const ChatRoom = (props: { user: userType, member: memberType, socket: WebSocket | undefined, close: Function, appendMessageToMember: Function }) => {
    const { user, member, socket } = props;

    /**
     * This is the range where we should scroll conversation to bottom on new messages,
     * otherwise the conversation should not get scrolled on new messages
     */
    const CONVERSATION_SCROLL_MODE_RANGE = 200;

    const [messages, setMessages] = useState<messageType[]>([]);
    const [newMessageValue, setNewMessageValue] = useState('');
    const [sendingMessage, setSendingMessage] = useState(false);

    const PAGINATION_PER_PAGE = 20;
    const [pagination, setPagination] = useState({ limit: PAGINATION_PER_PAGE, offset: 0, count_reached: false });;
    // -1: Component's initialization, 1: loading messages, 0: no process under execution
    const [loadingChatHistory, setLoadingChatHistory] = useState(-1);
    const [showUnreadMessagesBadge, setShowUnreadMessagesBadge] = useState(false);

    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const { t } = useTranslation();


    // User typing
    const USER_IS_TYPING_DELAY = 3000;
    const [_tm_id_, set_tm_id] = useState<any>();
    const [memberIsTyping, setMemberIsTyping] = useState(false);

    // Focus status
    const [memberInFocus, setMemberInFocus] = useState(false);

    // 
    var conversationInScroll = false;

    useEffect(() => {
        textAreaRef.current?.focus();
        listenToSocketMessage();

        try {
            loadConversationHistory().then((data: messageType[]) => {
                setMessages(data);

                initialChecks(data);

                if (pagination.offset === 0)
                    scrollConversationToBottom();
            })
                .catch((err) => { console.warn('Can\'t restore conversation history', err) });
        } catch (err) {
            console.log(err);
        }


        return (() => {
            sendFocusUpdate('close');

            setMessages([]);
            setNewMessageValue('');
            setSendingMessage(false);
            setShowUnreadMessagesBadge(false);
        });
    }, [member.id, showUnreadMessagesBadge])


    /**
     * Perform initial checks suck as mark unread messages as read..
     */
    const initialChecks = (data: messageType[]) => {
        markConversationAsRead(data);
    }


    const onScrollConversation = () => {
        conversationInScroll = true;

        // Call the scrollHandler 1000ms after scroll
        const scrollTimeoutID = setTimeout(() => {
            if (conversationInScroll) {
                handleConversationScroll();
                conversationInScroll = false;
            }
            clearTimeout(scrollTimeoutID);
        }, 1000);
    }

    /**
     * 
     * @param status string
     * Send focus status to chat member 
     */
    const sendFocusUpdate = (status: string) => {
        if (!socket || !socket.OPEN || socket.CONNECTING) return;

        const payload = {
            type: status === 'enter' ? 'enter_room' : 'close_room',
            recipient: member.id,
        };

        try {
            socket?.send(JSON.stringify(payload));
        } catch (e) {
            // Pass
        }
    }

    const listenToSocketMessage = () => {
        if (!socket || !socket.OPEN) return;

        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);

            if (data.type === "message") {
                processSocketMessage(data);
            }

            if (data.type === "delivery_report") {
                // Mark conversation as read
                setMessages(prev => (prev.map(m => m.sender_id == user.id && !m.read_at ? { ...m, read_at: new Date() } : m)));
            }

            if (["start_typing", "stopped_typing"].includes(data.type)) {
                // Typing status
                setMemberIsTyping(data.type == "stopped_typing" ? false : true);
                scrollConversationToBottom(false);
            }

            if (["enter_room", "close_room"].includes(data.type)) {
                // Typing status
                setMemberInFocus(data.type == "enter_room" ? true : false);
            }

        };


        if (socket.OPEN) {
            sendFocusUpdate('enter');
        }
    }

    const processSocketMessage = (data: { message: string, recipient_uid: string, sender_name: string, sender_uid: string, type: string }) => {
        const newMessage: messageType = {
            id: uuid4(),
            value: data.message,
            sent_at: new Date(),
            read_at: null,
            updated_at: null,
            sender_id: data.sender_uid,
            receiver_id: data.recipient_uid,
        };

        // Append message to the message state's variable if both sender and receiver are members of the current ChatRoom
        if ([user.id, member.id].includes(newMessage.receiver_id.toString()) && [user.id, member.id].includes(newMessage.sender_id.toString())) {
            appendMessageToConversation(newMessage);

            // Play sound notif
            const audioEl = incommingMessageRef.current;
            if (deviceIsMuted() && audioEl) {
                audioEl.play()
                    .then(() => { })
                    .catch(() => { })
            }
        }

        if (newMessage.sender_id != member.id) {
            toastNow({ message: newMessage.value, title: data.sender_name });

            // Append message to member in the parent component
            props.appendMessageToMember(newMessage);
        }

        if (!memberInFocus) {
            setMemberInFocus(true);
        }

        setMemberIsTyping(false);
    }

    const appendMessageToConversation = (newMessage: messageType) => {
        setMessages(prev => [...prev, newMessage]);

        // Append message to member in the parent component
        props.appendMessageToMember(newMessage);

        /**
         * Scroll if the conversation is not in browsing mode;
         * This involves checking if the user hasen't scrolled up
         * Considering a scroll of < CONVERSATION_SCROLL_MODE_RANGE means the user is not in scroll mode
         * 
         * clientHeight: The visible convContainer height in pixels
         * scrollTop: The remaining distance to reach the top of the container in pixels
         * scrollHeight: The total height of the container including the overflown part in pixels 
         */

        const convContainer = conversationHistoryRef.current;
        if (!convContainer) return;

        // Show badge notification if the user is reading the chat history
        if ((convContainer.scrollHeight - convContainer.clientHeight) - convContainer.scrollTop > CONVERSATION_SCROLL_MODE_RANGE) {
            setShowUnreadMessagesBadge(true);
        }

        scrollConversationToBottom(false);

        if (convContainer.scrollHeight == convContainer.clientHeight) {
            // Read message
            markConversationAsRead();

            // Send delivery signal
            // Send delivery report to sender
            const newSocketMessage = {
                type: "delivery_report",
                recipient: member.id
            };
            socket?.send(JSON.stringify(newSocketMessage));
        }
    }

    // [ Conversation scrolling
    const conversationHistoryRef = useRef<HTMLDivElement>(null);
    const handleConversationScroll = () => {
        const convContainer = conversationHistoryRef.current;
        if (!convContainer) return;

        /**
         * When thinking of prefetching more messages before the user reaches the top of conversation, consider this
         * First time will fetch more messages when the scrollTop is equal to 0
         * After that will launch the fetch if the scrollTop is smaller than 50
         * This is to prevent undesired API requests
         */
        if (convContainer?.scrollTop == 0 && !pagination.count_reached) {
            setPagination((prev) => ({ limit: prev.limit, offset: prev.offset + prev.limit, count_reached: prev.count_reached }))
        }


        const scrolledValue = convContainer.scrollHeight - (convContainer.clientHeight + convContainer.scrollTop);
        if (scrolledValue < CONVERSATION_SCROLL_MODE_RANGE) {
            setShowUnreadMessagesBadge(false);

            // Trigger mark conversation as read
            const toID = setTimeout(() => {
                clearTimeout(toID);
                markConversationAsRead();
            }, 200);


            // Send delivery report to sender
            const newSocketMessage = {
                type: "delivery_report",
                recipient: member.id
            };
            socket?.send(JSON.stringify(newSocketMessage));
        }



        // Infinite scroll; load chat history while scrolling
        if (!pagination.count_reached && convContainer.scrollTop < CONVERSATION_SCROLL_MODE_RANGE) {


            try {
                loadConversationHistory().then((data: messageType[]) => {
                    const topMessage = messages.sort((a, b) => a.sent_at < b.sent_at ? -1 : 0)[0];
                    setMessages((prev) => [...prev, ...data]);

                    // Scroll to last read message before appedning new messages
                    const messageContainer = document.querySelector(`#ms-${topMessage.id}`);
                    if (messageContainer) {
                        // setTimeout(() => {
                        //     messageContainer.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                        //     messageContainer.classList.add('blink');
                        // }, 300);
                    }
                })
                    .catch((err) => { console.warn('Can\'t load chat history', err) });
            } catch (err) {
                console.log(err);
            }
        }

    }

    /**
     * 
     * @param force if set to false then only scroll if the user not browsing the chat history
     */
    const scrollConversationToBottom = (force: boolean = true) => {
        const convContainer = conversationHistoryRef.current;
        if (!convContainer) return;

        let inBrowseMode = false;
        inBrowseMode = (convContainer.scrollHeight - convContainer.clientHeight) - convContainer.scrollTop > CONVERSATION_SCROLL_MODE_RANGE;

        if (inBrowseMode && !force) {
            return;
        }

        setTimeout(() => {
            convContainer.scrollTo({
                top: convContainer.scrollHeight,
                left: 0,
                behavior: "smooth"
            })
        }, 100);
    }
    //]

    // [ Send message
    const sendMessage = () => {
        // Focus again on compose message textarea
        textAreaRef.current?.focus();

        if (!socket) return;
        if (!newMessageValue) return console.log('Please compose your message first.');

        const newSocketMessage = {
            type: "message",
            recipient: member.id,
            message: newMessageValue.trim(),
        };
        socket?.send(JSON.stringify(newSocketMessage));

        const newMessage: messageType = {
            id: uuid4(),
            value: newMessageValue.trim(),
            sent_at: new Date(),
            read_at: null,
            updated_at: null,
            sender_id: user.id,
            receiver_id: member.id,
        };

        appendMessageToConversation(newMessage);

        // Post to backend
        postMessageToBackend({
            text: newMessageValue.trim(),
            receiver: member.id,
            sender: user.id,
        });

        setNewMessageValue('');
    }


    // Audio notifs
    const outgoingMessageRef = useRef<HTMLAudioElement>(null);
    const incommingMessageRef = useRef<HTMLAudioElement>(null);

    const postMessageToBackend = async (payload: { text: string, sender: string, receiver: string }) => {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URI;
        const req = await fetch(`${apiBaseUrl}/messages/`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
                text: payload.text,
                sender: payload.sender,
                receiver: payload.receiver,
            })
        });

        if (req.ok) {
            const data = await req.json();
            // Update the sending status (one check mark instead of waiting to be sent)

            // Play sound notif
            const audioEl = outgoingMessageRef.current;
            if (deviceIsMuted() && audioEl) {
                audioEl.play()
                    .then(() => { })
                    .catch(() => { })
            }
        } else {
            toast.error('Message could not be sent!')
        }
    }
    // Send message ]


    const loadConversationHistory = async (): Promise<messageType[]> => {
        if (loadingChatHistory === 1) return Promise.reject('Another request is being under execution!');

        setLoadingChatHistory(1);
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URI;
        const url = new URL(`${apiBaseUrl}/messages/`);
        url.searchParams.append('member1', user.id.toString());
        url.searchParams.append('member2', member.id.toString());
        url.searchParams.append('limit', pagination.limit.toString());
        url.searchParams.append('offset', pagination.offset.toString());

        const jsonHistory = await fetch(url);
        if (!jsonHistory.ok) {
            setLoadingChatHistory(0);
            return new Promise((resolve, reject) => {
                reject('Something went wrong while fetching conversation history');
            });
        }

        const data = await jsonHistory.json();

        // Update pagination accordingly preparing it for next request
        setPagination((prev) => ({ limit: prev.limit, offset: prev.offset + prev.limit, count_reached: !data.results.length }));

        const __: messageType[] = data.results?.map((item: any) => ({
            id: item.id,
            value: item.text,
            sent_at: new Date(item.sent_at),
            read_at: item.read_at ? new Date(item.read_at) : null,
            updated_at: item.updated_at ? new Date(item.updated_at) : null,
            sender_id: item.sender,
            receiver_id: item.receiver,
        }));

        setLoadingChatHistory(0);

        return new Promise((resolve, reject) => {
            resolve(__);
        });
    }


    /**
     * 
     * @param data messageType[] if not provided or received empty array, the state's messages will be used instead
     * @returns void
     */
    const markConversationAsRead = async (data: messageType[] = []) => {
        const __messages = data.length ? data : messages;

        // Make sure there's unread messages
        if (__messages.filter(m => m.sender_id == member.id && !m.read_at).length < 1) return;

        const apiBaseUrl = process.env.REACT_APP_API_BASE_URI;
        const fullUrl = `${apiBaseUrl}/messages/?recipient=${user.id}&sender=${member.id}`;

        const req = await fetch(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            method: 'PUT',
            body: JSON.stringify({})
        });

        if (!req.ok) {
            console.warn('Failed marking conversation as read!');
        }
        if (req.ok) {
            setMessages((prev) => prev.map(m => (m.sender_id == member.id ? { ...m, read_at: new Date() } : m)));
        }
    }

    // This is the number in ms to wait before send userStoppedTyping signal
    const messageInputKeyUp = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (!socket || socket.CONNECTING || !socket.OPEN) return;

        // Send on Enter clicked
        if (e.key.toLowerCase() == 'enter') {
            return sendMessage();
        }

        /**
         * Send 'Typing' signal to member
         * Once a key is pressed, send typing and start counting down (10 seconds for example)
         * When the counter is reached send stop typing signal
         */

        if (_tm_id_) {
            clearTimeout(_tm_id_);
        }


        if (!_tm_id_) {
            const newTypingSignal = {
                type: "start_typing",
                recipient: member.id
            };
            socket?.send(JSON.stringify(newTypingSignal));
        }



        set_tm_id(setTimeout(() => {
            const newTypingSignal = {
                type: "stopped_typing",
                recipient: member.id
            };
            socket?.send(JSON.stringify(newTypingSignal));
            clearTimeout(_tm_id_);
            set_tm_id(null);
        }, USER_IS_TYPING_DELAY));
    }

    const deviceIsMuted = (): boolean => {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();

        if (audioContext && audioContext.state == 'suspended') {
            return true;
        }

        return false;
    }

    return (
        <div className="h-full relative">
            <div className="flex ">
                <button className=" rounded-full w-8 h-8 bg-primary-200 text-primary-500 dark:bg-primary-950/80 dark:hover:bg-primary-900 transition" onClick={() => props.close()}>
                    <ArrowBack className="rtl:rotate-180 rtl:ml-2 ltr:mr-2" />
                </button>
                <h1 className="text-primary-500 dark:text-primary-300 text-center text-xl my-4 mt-auto flex">
                    <span className="w-max">{member.pseudoname}</span>
                    {
                        !memberInFocus ? <></>
                            : <>
                                <Adjust className="text-teal-700 dark:text-teal-400 ltr:ml-2 rtl:mr-2 my-auto p-[2px]" />
                            </>
                    }
                </h1>

                {/* Call actions */}
                <Streaming user={user} member={member} blurTyping={() => { textAreaRef.current?.blur() }} />
            </div>


            <div className="max-w-fit m-auto">
                <Grid
                    visible={[-1, 1].includes(loadingChatHistory)}
                    height="40"
                    width="40"
                    color="#3b82f6"
                    ariaLabel="grid-loading"
                    radius="10.5"
                    wrapperStyle={{}}
                    wrapperClass="grid-wrapper"
                />
            </div>


            {/* Chat history */}
            <div ref={conversationHistoryRef} onScroll={onScrollConversation} className="p-2 h-[82%] overflow-y-auto scrollbar-thin scrollbar-thumb-primary-50/80 scrollbar-track-white dark:scrollbar-thumb-primary-950 dark:scrollbar-track-black/90">
                {/* Message */}

                {
                    !(messages.length < 1 && loadingChatHistory === 0) ? <></>
                        : (<div className="text-center text-primary-500 dark:text-primary-900 w-full pt-8">
                            <Drafts className="" style={{ height: '80px', width: '80px' }} />
                        </div>)
                }


                {
                    pagination.count_reached && pagination.offset && messages.length
                        ? <div className={
                            ["text-center w-full rounded-t-2xl mt-0.5",
                                'rounded-2xl',
                            ].join(' ')
                        }>
                            <div className='my-1 relative flex p-1 text-primary-700 dark:text-primary-300'>
                                <p className='mx-auto max-w-max text-primary-700/60 dark:text-primary-300/60'>{t('chatBeginning')}</p>
                            </div>

                        </div>
                        : ''
                }

                {
                    messages.sort((a, b) => a.sent_at < b.sent_at ? -1 : 0).map(msg => (
                        <div key={msg.id} id={`ms-${msg.id}`} className={
                            [" w-fit max-w-[80%] rounded-t-2xl mt-0.5",
                                'bg-primary-50 dark:bg-primary-950/80 border border-primary-200 dark:border-primary-900',
                                msg.sender_id == user.id ? 'ltr:ml-auto rounded-bl-2xl' : 'rtl:mr-auto rounded-br-2xl',
                            ].join(' ')
                        }>
                            <div className='my-1 relative flex p-1 text-primary-700 dark:text-primary-300'>
                                <p className='max-w-max'>{msg.value}</p>
                                <div className='mt-auto text-xs italic ltr:ml-2 rtl:mr-2 flex'>
                                    <span className="text-primary-700 dark:text-primary-400">{formatDate(msg.sent_at)}</span>
                                    {
                                        msg.sender_id == user.id
                                            ? <div className="flex w-6">
                                                <Check className="text-primary-700 dark:text-primary-400 ltr:ml-1 rtl:mr-1" style={{ fontSize: '13px' }} />
                                                {
                                                    msg.read_at
                                                        ? <Check className="text-primary-700 dark:text-primary-400 ltr:-ml-2 rtl:-mr-2" style={{ fontSize: '13px' }} />
                                                        : ''
                                                }

                                            </div>
                                            : ''
                                    }
                                </div>
                            </div>

                        </div>
                    ))
                }

                {/* Typing */}
                {
                    memberIsTyping
                        ? <div className={
                            [" w-fit max-w-[80%] rounded-t-2xl mt-0.5",
                                'bg-primary-50 dark:bg-primary-950/50 border border-primary-50 dark:border-primary-950/50',
                                'rounded-br-2xl ltr:mr-auto rtl:mr-auto',
                            ].join(' ')
                        }>
                            <div className='my-1 relative flex p-1 text-primary-700 dark:text-primary-300 typing'>
                                {/* <p className='max-w-max italic'>{t('typing')}</p> */}
                                <Circle className="text-primary-400" />
                                <Circle className="text-primary-400" />
                                <Circle className="text-primary-400" />
                            </div>

                        </div>
                        : <></>
                }
            </div>


            {/* Compose message */}
            <div className="bg-white dark:bg-black/90 h-[10%] w-[90%] md:w-[80%] rounded-t-2xl p-1 flex absolute bottom-0 left-[5%] md:left-[10%] shadow-primary-300 dark:shadow-primary-700/50" style={{ boxShadow: `0px 0px 5px 1px var(--tw-shadow-color)` }}>
                {/* Unread messages badge */}
                {
                    !showUnreadMessagesBadge ? <></>
                        : <button onClick={() => scrollConversationToBottom()} className="flex absolute text-sm rounded-full w-11 h-11 p-1 -top-12 right-0 border border-primary-300 dark:border-primary-900 bg-primary-50 text-primary-700 dark:bg-primary-950/80 dark:text-primary-300">
                            <span className=" my-auto mx-auto">{messages.filter(m => m.sender_id == member.id && !m.read_at).length}</span>
                            <ArrowDownward className="h-2 w-2 my-auto" />
                        </button>
                }

                <textarea ref={textAreaRef} onKeyUp={(e) => messageInputKeyUp(e)} onChange={e => setNewMessageValue(e.target.value)} className="w-full outline-none border-none shadow-none rounded-md p-2 resize-none text-primary-500 bg-white dark:bg-black/90 dark:text-primary-100 placeholder-primary-500/60 dark:placeholder-primary-300 overflow-y-scroll scrollbar-thin scrollbar-thumb-primary-50 scrollbar-track-white dark:scrollbar-thumb-black/90 dark:scrollbar-track-black/90" placeholder={t('composeMessagePlaceholder')} value={newMessageValue} />
                <button disabled={sendingMessage} onClick={() => sendMessage()} className="ring-1 ring-primary-500/60 w-auto h-fit p-1 rounded-full ml-2 cursor-pointer text-primary-500 dark:text-primary-300 bg-white hover:bg-primary-50 dark:bg-black/90 dark:hover:bg-primary-950 text-center transition">
                    {
                        sendingMessage
                            ? <ThreeDots
                                visible={true}
                                height="20"
                                width="20"
                                color="#3b82f6"
                                radius="9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                            : <div className="text-base flex ">
                                <span className="">{t('send')}</span>
                                <SendOutlined className="ml-1 rtl:rotate-180" />
                            </div>
                    }
                </button>
            </div>


            {/* Audio sound notifications */}
            <audio ref={outgoingMessageRef} src={outgoingMessageSound} />
            <audio ref={incommingMessageRef} src={incommingMessageSound} />


            {/* Notifications */}
            <Toaster />
        </div>
    );
}

export default ChatRoom;