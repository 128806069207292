import { StrictMode, createContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import '../i18n';
import SignIn from './signin';
import { userType } from '../helpers/types/types';
import Welcome from './welcome';
import { generateUniqueId } from '../helpers/common';

import { PeerContext } from '../context/PeerContext';
import Peer, { PeerOptions } from 'peerjs';

const ThemeContext = createContext('dark');
export { ThemeContext };




const MainApp = () => {
    const [checkingSession, setCheckingSession] = useState(true);
    const [darkMode, setDarkMode] = useState<string | null>('dark');
    const [user, setUser] = useState<userType>();

    const welcomeComponentRef = useRef<any>();

    const [peerClient, setPeerClient] = useState<Peer | null>(null);
    const port: number = process.env.REACT_APP_PEER_JS_PORT ? parseInt(process.env.REACT_APP_PEER_JS_PORT) : 443;
    const peerClientOptions: PeerOptions = {
        host: process.env.REACT_APP_PEER_JS_HOST,
        port: port,
        path: '/',
        secure: true,
    };


    useEffect(() => {
        // Dark mode detection from localStorage or OS preference in order
        let _darkMode = localStorage.getItem('darkMode');
        if (!_darkMode && window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches) {
            _darkMode = 'light';
        }
        setDarkMode(_darkMode ? _darkMode : darkMode);

        // 
        detectLang();

        checkAuth();

    }, [])


    const initPeerClient = (connectedUser: userType) => {
        const peer = new Peer(connectedUser.id, peerClientOptions);
        peer.on('open', (id) => {
            // console.log(`Peer client open id ${id}`);
            setPeerClient(peer);
        })
    }




    const toggleDarkMode = () => {
        const newVal = (darkMode === 'dark') ? 'light' : 'dark';
        setDarkMode(prev => (newVal));
        localStorage.setItem('darkMode', newVal);
    }


    //[
    /**
     * Internationalization
     */
    const { i18n: { changeLanguage, language } } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(language)
    const handleChangeLanguage = (newLanguage: string) => {
        setCurrentLanguage(newLanguage);
        changeLanguage(newLanguage);
        localStorage.setItem('_lang', newLanguage);
    }

    const detectLang = () => {
        const browserLanguage = (): string => {
            let _lang = 'en';
            const supportedLanguages = ['ar', 'en', 'fr', 'es'];;
            const dateLocale = Intl.DateTimeFormat().resolvedOptions().locale;
            if (dateLocale && dateLocale.length) {
                const browserLang = dateLocale.split('-')[0];
                _lang = supportedLanguages.includes(browserLang) ? browserLang : _lang;
            }

            return _lang;
        }

        const lang = localStorage.getItem('_lang');
        setCurrentLanguage(lang ?? browserLanguage());
        changeLanguage(lang ?? browserLanguage());
    }
    //]

    // [ User authentication
    const setUserName = (username: string) => {
        const userId = generateUniqueId();
        const __user = {
            pseudoname: username,
            id: userId,
        };
        setUser(__user);
        localStorage.setItem('user', JSON.stringify(__user));
        initPeerClient(__user);
    }

    const checkAuth = () => {
        setTimeout(() => {
            const __user_json = localStorage.getItem('user');
            if (__user_json) {
                try {
                    // 
                    const __user: { pseudoname: string, id: string } = JSON.parse(__user_json);
                    setUser(__user);
                    initPeerClient(__user);
                } catch (e) {
                    console.error(`Cannot parse user from localStorage`, e);
                }
            }
            setCheckingSession(false);
        }, 1);
    }

    const logout = () => {
        setUser(undefined);
        // Remove from local storage
        localStorage.removeItem('user');

        if (welcomeComponentRef.current) {
            welcomeComponentRef.current.closeSocket();
        }
    }
    // User authentication ]


    return (
        <StrictMode>
            <ThemeContext.Provider value={darkMode ? darkMode : 'dark'}>
                <div dir={currentLanguage == 'ar' ? 'rtl' : 'ltr'} className={["transition-all bg-primary-50/30 dark:bg-black h-screen m-0 p-0 relative", darkMode === 'dark' ? 'dark' : ''].join(' ')}>
                    {/* Login form */}

                    {
                        checkingSession
                            ? <div className='p-4'>
                                <p className='text-primary-700 dark:text-primary-50'>Checking session</p>
                            </div>
                            : <></>
                    }

                    {
                        !user && !checkingSession
                            ? <div className='md:w-1/3 px-2 md:px-0 ml-1 md:mx-auto mt-0 py-1 md:py-36'>
                                <SignIn setUserName={(username: string) => setUserName(username)} />
                            </div>

                            : <></>
                    }


                    {/* <Chat room="abdelilah-duo-room" /> */}


                    {
                        !user ? <></>
                            : <PeerContext.Provider value={peerClient}>
                                <div className='md:w-2/3 mx-auto mt-0 md:py-24 h-screen'>
                                    <Welcome handleChangeLanguage={handleChangeLanguage} ref={welcomeComponentRef} toggleDarkMode={toggleDarkMode} logout={() => logout()} user={user} />
                                </div>
                            </PeerContext.Provider>
                    }
                </div>
            </ThemeContext.Provider>
        </StrictMode>
    )
}


export default MainApp;